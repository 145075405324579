import { getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Util from '@/common/utils/Util'
import queryString from 'query-string'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const { getters, dispatch } = useStore()

  const init = async () => {
    const startIndex = route.fullPath.indexOf('?')
    const jsonStr = route.fullPath.substr(startIndex + 1)
    if (jsonStr.indexOf('alreadyUser') > -1) {
      const query = queryString.parse(jsonStr)
      if (query.alreadyUser) {
        // 이미 가입
        window.opener.parent.postMessage(
          {
            source: 'niceAuthPopSuccessAndAlready'
          },
          '*'
        )
      }
    } else {
      const params = JSON.parse(decodeURIComponent(jsonStr))
      const registerForm = Object.assign(getters['user/getRegisterForm'])
      registerForm.name = params.name
      registerForm.nick = params.name
      registerForm.phone = params.phone
      registerForm.niceId = params.niceId
      registerForm.niceSeq = params.niceSeq
      registerForm.birth = params.birth
      registerForm.isPhoneAuth = proxy.$ConstCode.BOOLEAN_VALUE.TRUE
      await dispatch('user/updateRegisterForm', { registerForm })
      window.opener.parent.postMessage(
        {
          source: 'niceAuthPopSuccess',
          params: params
        },
        '*'
      )
    }
    handlerClose()
  }

  const handlerClose = () => {
    window.addEventListener('message', function({ data }) {
      if (!Util.isEmpty(data.source) && data.source === 'niceAuthPopClose') {
        // 본인인증 콜백 message 처리
        window.self.close()
      }
    })
  }

  init()

  return { init, handlerClose }
}
